@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');
@import 'responsive';

@mixin item-info() {
  .item-title {
    font-size: 21px;
    width: 190px;
    line-height: 28px;
    @include media(sm) {
      max-width: 160px;
      font-size: 15px;
      line-height: 21px;
    }

    @include media(xs) {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 21px;
      width: 100%;
    }
  }

  .single.line {
    margin-right: 10px;
  }

  img.image {
    max-width: 90px;
    @include media(xs) {
      max-width: 65px;
    }
    margin-bottom: 7px;
  }
}


body {
  font-family: 'Raleway', sans-serif;
  @include media(xs) {
    padding-top: 94px;
  }
}

.menu-enter-active, .fade-leave-active {
  transition: all .5s;
  transform: translateX(0);
}

.menu-enter, .menu-leave-active {
  transition: all .5s;
  opacity: 0;
  transform: translateX(-100px);
}

#menu.ui.menu {
  border-bottom: 4px solid #FF6501;
  background-color: #fff;
  @include media(xs) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
}

.head-menu-list {
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  list-style: none;
  li {
    &:hover,
    &:focus,
    &.active {
      a {
        color: #FF6501;
      }
    }
    &:not(:last-child) {
      a {
        border-right: 1px solid #7B8D8D;
      }
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      color: #7B8D8D;
      padding: 0 15px;
      font-size: 14px;
    }
  }
}

.menu-wrapper .asside-menu {
  width: 256px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #4A4A4A;
  z-index: 999;
  color: #979797;
  display: none;
  flex-direction: column;
  &.active {
    display: flex;
  }
  .close-wrapper {
    padding: 20px 25px 0;
     text-align: right;
     span {
       text-transform: uppercase;
       color: #F1F1F1;
       font-size: 13px;
     }
  }
  .center-menu {
    flex: 1;
    display: flex;
    overflow: auto;
    margin: 50px 0;
    flex-direction: column;
  }
  ul {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    li {
      border-top: 1px solid #9B9B9B;

      &.show {
        background-color: #5f5d5d;
      }
      a {
        color: #F1F1F1;
        text-align: right;
        padding: 0px 25px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;
        font-size: 13px;
      }

      .dropdown-menu {
        background-color: transparent;
        margin: 0;
        border: 0;

        a {
          background-color: transparent;
          height: 25px;
          font-size: 12px;
        }
      }
    }
  }
  .menu-footer {
    padding-bottom: 10px;
    text-align: center;
    p {
      font-size: 12px;
      margin-bottom: 0;
      color: #F1F1F1;
      margin-bottom: 3px;
    }
    a {
      color: #F1F1F1;
      display: block;
      margin-bottom: 1px;
      font-size: 18px;
    }
  }
}

.overlay-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0, 0.5);
  top: 0;
  left: 0;
  z-index: 900;
  display: none;
  &.active {
    display: block;
  }
}

#qr-scanner {
  width: 80%;
}

.mobile-back-button {
  margin-left: -10px;

  img {
    width: 50px;
  }
}

.mobile-menu-button {
  position: relative;
  height: 20px;

  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #000;
  }

  &:before {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: #000;
  }

  .line {
    position: absolute;
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background-color: #000;
    top: 50%;
    margin-top: -1px;
  }
}

.menu-wrapper {
  padding-top: 20px;
  margin-bottom: 10px;
  @include media(xs) {
    margin-bottom: 20px;
  }

  .js-menu {
    @include media(xs) {
      width: 78px;

      &.with-qr-button {
        width: 53px;
      }

      &.with-back-button {
        width: 28px;
      }
    }
  }

  .user-dash-lnk {
    display: inline-block;
    margin-left: 18px;
    img {
      height: 28px;
    }
  }
  .header-logo-wrapper {
    flex: 1;
    img {
      height: 50px;
    }
    @include media(xs) {
      text-align: center;
    }
  }

  .auth-wrapper {
    display: flex;
    align-items: center;

    .caret-wrapper {
      position: relative;

      .badge {
        position: absolute;
        width: 20px;
        height: 20px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6DC809;
        top: -3px;
        right: -12px;
        font-size: 12px;
      }

      img {
        width: 30px;
      }
    }
  }

  .un-auth-wrapper {
    justify-content: space-between;
    display: flex;
    align-items: center;

    .flex-auth {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .divider {
      width: 2px;
      height: 18px;
      margin: 0 30px;
      background-color: #000000;
      height: 14px;
      @include media(xs) {
        margin: 0 15px;
        height: 11px;
      }
    }

    a {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: -0.05px;

      @include media(xs) {
        font-size: 13px;
      }
    }
  }
}

.btn-action {
  background-color: #FF6501;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 45px;
  padding: 0 20px;
  border-radius: 25px;
  border-color: #FF6501;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #FF6501;
  }
}

.logo-wrapper {
  text-align: center;
  a {
    text-decoration: none;
  }

  @include media(xs) {
    img {
      width: 40px;
    }
  }

  .logo-txt {
    font-size: 48px;
    color: #000000;
    letter-spacing: -0.04px;
    text-align: center;
    display: block;
    text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    margin-bottom: 10px;

    @include media(xs) {
      font-size: 28px;
    }
  }
}

.menu-list {
  margin: 0;
  text-align: center;
  @include media(xs) {
    display: none;
  }

  li {
    display: inline-flex;
    margin: 0 45px;

    a {
      color: #000;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 15px 0;
      text-decoration: none;
    }
  }
}

.product-wrapper-list {
  .product-item {
    width: 100%;
    background-color: #F3F3F3;
    color: #191919;
    text-align: center;
    margin-bottom: 15px;

    .btn {
      width: 110px;
      height: 40px;
      font-size: 10px;
    }

    .img-wrapp {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      img {
        max-width: 100%;
      }
    }

    .product-item-description {
      padding: 0 20px 20px;
    }

    .divider {
      height: 1px;
      background-color: #979797;
      margin: 10px 0;
    }

    .category {
      font-size: 12px;
      text-transform: uppercase;
    }

    .name {
      font-size: 14px;
      // @include media(xs) {
      //   font-size: 16px;
      //   margin-bottom: 10px;
      // }
    }

    .price {
      font-size: 18px;
      position: relative;

      .line {
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: #FD0A0A;
      }
    }

    .retail {
      font-size: 10px;
      color: #9B9B9B;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .number {
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include media(xs) {
        font-size: 10px;
      }
    }
  }
}

.product-line {
  height: 14px;
  background-color: #4A90E2;
  margin-bottom: 75px;
}

.calculator-box {
  background-color: #FF6501;
  text-align: center;
  padding:  15px 0;
  position: relative;

  // Disable selection on double click
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .form & input {
    padding: 0 10px;
  }

  .triangle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 255px 0 255px;
    border-color: #ff6701 transparent transparent transparent;
  }

  .title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
  }

  .zip-code {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 16px;
    margin-bottom: 20px;

    .title {
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }

    input {
      display: inline-block;
      background-color: #fff;
      height: 40px;
      width: 100px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }

    span {
      font-weight: bold;
    }
  }

  .quantity {
    display: inline-flex;
    margin-bottom: 15px;

    .sylius-quantity {
      span {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
      }
      input {
        display: inline-block;
        background-clip: border-box;
        border: 1px solid #fff;
        background-color: #fff;
        height: 40px;
        width: 60px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;

        // Don't combine these two using , as they don't seem to work if not separate
        &::selection {
          background-color: #fff;
        }
        &::-moz-selection {
          background-color: #fff;
        }
      }
    }
  }

  .btn-orange-opposite {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
  }

  .roll-wrap {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.calc-info {
  padding-top: 50px;
  background-color: #fff;

  .center-content {
    text-align: center;

    .price-per-rol {
      font-size: 16px;
      color: #5C5C5C;
    }

    .discount {
      display: inline-flex;
      width: 124px;
      height: 56px;
      background: url('../img/tag_price.png') center center;
      background-size: cover;
      padding: 15px 0 0 18px;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 80%;
        top: 50%;
        right: -80%;
        background-color: #979797;
      }
      &:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        width: 80%;
        top: 50%;
        left: -80%;
        background-color: #979797;
      }
      .txt {
        font-size: 12px;
        color: #4D6B2D;
        text-transform: uppercase;
        display: block;
        width: 55px;
        line-height: 14px;
        span {
          display: block;
          font-size: 18px;
        }
      }
    }
  }

  .price {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    margin-bottom: 10px;

    .title {
      text-transform: uppercase;
      font-size: 15px;
      color: #7E9AA5;
      letter-spacing: -0.94px;
      line-height: 10px;
      text-align: center;
    }

    .retail {
      span {
        position: relative;

        // &:after {
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   top: 50%;
        //   margin-top: -1px;
        //   left: 0;
        //   height: 3px;
        //   width: 100%;
        //   background-color: #FD0A0A;
        // }
      }
    }

    span {
      font-size: 23px;
      color: #000;
    }
  }
}

.product-gray-wrapper {
  background-color: #707070;
  padding: 15px 30px;
  font-size: 14px;
  color: #fff;

  .price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .shipping {
    font-size: 12px;
  }
}

.product-short-description {
  font-size: 16px;
  margin-bottom: 35px;

  p {
    margin-bottom: 10px;
  }
}

.product-full-description {
  margin-bottom: 50px;
  font-size: 13px;

  .vip-action {
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }

  .title {
    margin: 30px 0 10px;
    color: #7E9AA5;
    font-size: 16px;
    @include media(xs) {
      font-size: 15px;
    }
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
    li {
      padding-left: 1em;
      text-indent: -.7em;
      &::before {
        content: "• ";
        color: #FF6501;
      }
    }
  }
}

.add-to-card-wrapper {
  form {
    display: flex;
    align-items: center;
    @include media(md) {
      display: block;
    }
  }
}

.product-img-wrapper {
  text-align: center;
  @include media(xs) {
    padding-bottom: 30px;
    background-color: #F1F1F1;
  }
  h1.ui.header {
    margin: 0 0 5px;
    color: #000;
    text-align: center;
    font-size: 24px;
  }
  .divider {
    width: 220px;
    margin: 15px auto;
    height: 1px;
    background-color: #979797;
  }
  img {max-width: 100%;}

  .type {
    font-size: 18px;
    text-transform: uppercase
  }
}

.call-to-action-wrapper {
  text-align: center;
  background: rgb(149,191,91);
  background: -moz-linear-gradient(top, rgba(149,191,91,1) 0%, rgba(132,164,99,1) 100%, rgba(132,164,99,1) 100%, rgba(132,164,99,1) 100%, rgba(132,164,99,1) 100%, rgba(132,164,99,1) 100%);
  background: -webkit-linear-gradient(top, rgba(149,191,91,1) 0%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%);
  background: linear-gradient(to bottom, rgba(149,191,91,1) 0%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%,rgba(132,164,99,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95bf5b', endColorstr='#84a463',GradientType=0 );  padding: 15px 0 9px;
  color: #fff;
  p {
    font-size: 17px;
    margin-bottom: 5px;
    @include media(xs) {
      font-size: 12px;
    }
  }
  a {
    font-size: 17px;
    color: #fff;
    display: inline-block;
    @include media(xs) {
      font-size: 12px;
    }
    &.phone {
      font-size: 26px;
      line-height: 20px;
      @include media(xs) {
        font-size: 18px;
      }
    }
  }
}

footer {
  .footer-gray {
    background-color: #4D4D4D;
    padding: 15px 0;
    .title {
      font-size: 19px;
      @include media(xs) {
        font-size: 13px;
      }
      color: #FFFFFF;
      letter-spacing: 0.04px;
      text-align: center;
      margin-bottom: 20px;
    }
    .copy-right {
      font-size: 14px;
      color: #FF6501;
      letter-spacing: 0.04px;
      text-align: center;
      @include media(xs) {
        font-size: 11px;
      }
    }
    .btn {
      display: block;
      margin: 0 auto 20px;
      width: 100px;
      height: 40px;
      font-size: 13px;
      line-height: 26px;
    }
    .footer-links {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .line {
        margin: 0 20px;
        height: 10px;
        width: 1px;
        background-color: #fff;
      }
      a {
        width: 145px;
        font-size: 14px;
        color: #F1F1F1;
        letter-spacing: 0.04px;
        color: #fff;
        text-decoration: underline;
        @include media(xs) {
          font-size: 11px;
          width: 110px;
        }
      }
    }
  }
}

h1.ui.header {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;

  @include media(xs) {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
  }
  .content {
    text-transform: uppercase;
  }
  .header-sub-title {
    font-size: 13px;
    color: #787878;
  }
}

.form-control {
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 0 12px;
  height: 45px;
  color: #495057;
  background-color: #D8D8D8;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: 15px;

  @include media(xs) {
    height: 46px;
    margin-bottom: 15px;
    font-size: 13px;
  }
}

.orange-text {
  &,
  &:hover,
  &:focus {
    color: #FF6501;
  }
}

.btn-orange {
  min-width: 160px;
  height: 50px;
  background: #FF6501;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  border-radius: 0;
  border-color: #FF6501;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  @include media(xs) {
    line-height: 11px;
    width: 110px;
    height: 40px;
    font-size: 10px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: #FF6501;
  }
}

.btn-orange-opposite {
  @extend .btn-orange;
  background-color: #fff;
  color: #FF6501;

  &.btn-transparent {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: #FF6501;
    color: #fff;
  }
}

.btn-login {
  margin-right: 25px;
}

.welcome-message {
  color: #191919;
  margin-bottom: 50px;
  font-size: 20px;
  text-align: center;
  @include media(xs) {
    font-size: 13px;
    text-align: left;
  }
}

.new-customer-title {
  font-size: 15px;
  display: inline-block;
}

.form {
  label {
    display: none;
  }

  input {
    @extend .form-control;
  }

  .field {
    width: 100%;
    padding: 0;
  }
}

.custom-content-wrapper {
  @include media(xs) {
    padding: 0 15px;
  }
}

.negative.message {
  color: red;
}

.flex-nav {
  margin-bottom: 30px;
  display: flex;
  .links {
    font-size: 14px;
    @include media(xs) {
      font-size: 13px;
    }
    h4 {
      margin: 0;
      font-size: 18px;
      @include media(xs) {
        font-size: 13px;
      }
    }
    a {
      color: #FF6501;
    }
  }
}

.cart-title {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 25px;
  padding-left: 15px;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  @include media(xs) {
    text-align: left;
  }
}

.cart-list {
  margin-bottom: 20px;
}

.cart-item {
  border-top: 2px solid #979797;
  padding-top: 30px;
  border-bottom: 1px solid #979797;

  @include media(xs) {
    border-bottom: 0;
    padding-top: 10px;
    margin-bottom: 0;
  }

  .title-quant-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .cart-main-info {
    display: flex;
    align-items: flex-start;
    align-items: center;
    margin-bottom: 20px;
    @include media(sm) {
      align-items: flex-start;
    }

    @include item-info();
  }

  .price-row {
    display: flex;
    flex: 1;
    margin-left: 30px;
    @include media(sm) {
      margin-left: 10px;
    }
    @include media(xs) {
      margin-left: 0;
    }

    @include media(xs) {
      margin-bottom: 25px;
    }

    .left-price {
      flex: 1;
      display: flex;
    }

    .title {
      height: 32px;
      background-color: #7795A0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 13px;
      text-transform: uppercase;

      @include media(sm) {
        height: 23px;
        font-size: 12px;
      }

      &.black {
        background-color: #000;

        @media (max-width: 991px) and (min-width: 768px) {
          width: 80px;
        }
      }
    }

    .amount-box {
      width: 100px;
      .title {
        background-color: #FF6501;
      }

      .content {
        background-color: #D8D8D8;
        padding-top: 3px;
        text-align: center;

        .discount {
          width: 68px;
          height: 42px;
          background: url('../img//discount.png') center center;
          background-size: cover;
          margin: 0 auto 3px;
          .txt {
            font-size: 8px;
            line-height: 8px;
            text-transform: uppercase;
            padding: 14px 14px 0 0;
            text-align: center;
            transform: rotate(-13deg);
            color: #4D6B2D;
          }
        }
      }
    }

    .price-box {
      flex: 1;
      margin-right: 1px;

      &:last-child {
        margin-left: 15px;
        @include media(sm) {
          margin-left: 10px;
        }
        @include media(xs) {
          margin-left: 0;
        }
      }
      .value {
        background-color: #D8D8D8;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        @include media(sm) {
          font-size: 16px;
          height: 25px;
        }
      }
    }
  }

  .item-nav {
    padding-top: 30px;
    margin-bottom: 45px;
    border-top: 1px solid #979797;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}

.quantity {
  display: flex;
  align-items: center;

  // Disable selection on double click
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .calculator-box & {
    align-items: flex-end;
  }

  @include media(sm) {
    margin-left: 10px;
  }

  .sylius-quantity {
    text-align: center;
    span {
      font-size: 12px;
    }
    input {
      margin: 0;
      text-align: center;
      width: 94px;
      height: 66px;
      background-color: transparent;
      border: 1px solid #828282;
      font-size: 14px;
      @include media(sm) {
        font-size: 12px;
        height: 37px;
        width: 40px;
        padding: 0;
      }
    }
  }
  label {
    display: none;
  }
  .btn-quantity {
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 50%;
    border: 1px solid #828282;
    cursor: pointer;
    margin: 0 10px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 1px;
      background-color: #828282;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.increase:after {
      content: '';
      display: block;
      width: 1px;
      height: 15px;
      background-color: #828282;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .calculator-box & {
      &:before,
      &.increase:after {
        background-color: #fff;
      }
      &:before {
        color: #fff;
      }
      border-color: #fff;
      margin: 0;
    }

    @include media(sm) {
      height: 38px;
      width: 38px;
    }
    @include media(xxxs) {
      display: none;
    }
  }
}

.total-wrapper {
  background-color: #F4F4F4;
  padding: 15px 0 70px;
  .total-btns {
    text-align: right;
    @include media(xs) {
      text-align: left;
    }
  }
  .total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 15px;
    @include media(xs) {
      padding: 0;
    }
    span {
      font-size: 20px;
      color: #5C5C5C;
      text-transform: uppercase;
      @include media(xs) {
        font-size: 16px;
      }

      &.total {
        font-size: 32px;
        color: #000;
        @include media(xs) {
          font-size: 22px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #417505;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    margin: 55px 0 15px;
  }

  .shipping {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    @include media(xs) {
      padding: 0;
    }

    .left {
      color: #5C5C5C;
      .title {
        font-size: 21px;
        text-transform: uppercase;
        @include media(xs) {
          font-size: 16px;
        }
      }
      .description {
        font-size: 20px;
        @include media(xs) {
          font-size: 10px;
        }
      }
    }
    .info-shipping {
      text-transform: uppercase;
      font-size: 20px;
      color: #000;
      @include media(xs) {
        font-size: 15px;
      }
    }
  }
}

.back-wrapper {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
  color: #FF6501;
  padding: 10px 0;
  margin-bottom: 10px;

  a {
    color: #9B9B9B;
  }
}

.vip-programm {
  .title {
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    line-height: 25px;
    color: #000;
  }
  .sub-title {
    font-size: 13px;
    color: #787878;
    margin-bottom: 50px;
  }
  .vip-description {
    font-size: 15px;
    margin-bottom: 25px;
  }
  .benefit-list {
    font-size: 14px;
    color: #FF6501;
    padding-left: 15px;
    margin-bottom: 60px;
  }
  .call_us {
    font-size: 22px;
    color: #000;
    text-transform: uppercase;
    margin: 0;
    line-height: 25px;
  }
  .phone {
    color: #000;
    font-size: 24px;
    display: inline-block;
    margin-bottom: 70px;
  }
}

.thanks-message {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 15px;
}

.proceed-message {
  font-size: 15px;
  margin-bottom: 70px;
}

.form-group-title {
  text-transform: uppercase;
  font-size: 18px;
  color: #FF6501;
  margin-bottom: 5px;
}

.custom-select {
  @extend .form-control;
  display: flex;
  select {
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    &::focus {
      outline: none;
    }
  }
}

.double-input {
  display: flex;

  input,
  .field {
    &:first-child {
      margin-right: 5px;
    }
  }
}

.plus-round-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #81A15F;
  color: #fff;
  font-size: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: rgba(0,0,0, 0.5) 0 2px 4px 0;
  margin-bottom: 20px;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: black;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 black,
      4px 0 0 black,
      4px -2px 0 black,
      4px -4px 0 black,
      4px -6px 0 black,
      4px -8px 0 black;
    transform: rotate(45deg);
  }
}

.custom-checkbox {
  margin-bottom: 4px;
}

.carusel-wrapper {
  padding: 0;
  .carousel-caption {
    color: #fff;
    right: auto;
    left: 8%;
    top: 10%;
    text-align: left;
    padding: 0;
    @include media(xs) {
      left: 25px;
      bottom: 30px;
    }
    .btn {
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 16px;
      color: #FF6501;
      background-color: #fff;
      border: 1px solid #fff;
      font-weight: bold;
      &:hover,
      &:focus {
        background-color: transparent;
        color: #fff;
        border-color: #FF6501;
        background-color: #FF6501;
      }
      @include media(xs) {
        font-size: 10px;
        width: 40%;
        height: 15%;
        min-width: 100px;
        min-height: 26px;
      }
    }

    h2 {
      font-size: 42px;
      color: #FFFFFF;
      letter-spacing: 0.06px;

      @include media(xs) {
        font-size: 18.25px;
        letter-spacing: 0.03px;
      }
    }

    p {
      font-size: 17px;
      color: #D9FCFF;
      letter-spacing: 0.42px;
      line-height: 28px;
      @include media(xs) {
        font-size: 8px;
        line-height: 12.17px;
      }
    }
  }
  .carousel-indicators {
    bottom: 50px;
    justify-content: flex-start;
    left: 80px;
    margin: 0;
    @include media(xs) {
      left: 25px;
      bottom: 15px;
    }
    li {
      width: 20px;
      height: 20px;
      background-color: #D8D8D8;
      border-radius: 50%;
      border: 1px solid hsl(0, 0%, 59%);
      @include media(xs) {
        width: 13px;
        height: 13px;
      }

      &.active {
        background-color: #949692;
        border-color: #828581;
      }
    }
  }
}
.js-calculator {
  position: relative;
}
.calc-wrapper {
  overflow: hidden;

  .call-to-action-wrapper {
    position: absolute;
    bottom: -90px;
    left: 15px;
    width: calc(100% - 30px);
    z-index: 1;

    p {
      font-size: 14px;
    }
    a {
      font-size: 15px;
      &.phone {
        font-size: 21px;
      }
    }
  }
}

.wrapper-desktop {
  display: flex;
  align-items: flex-start;
  flex: 1;
  background: rgb(30,87,153);
  background: -moz-linear-gradient(-45deg, rgba(30,87,153,1) 0%, rgba(244,246,247,1) 0%, rgba(225,231,234,1) 100%);
  background: -webkit-linear-gradient(-45deg, rgba(30,87,153,1) 0%,rgba(244,246,247,1) 0%,rgba(225,231,234,1) 100%);
  background: linear-gradient(135deg, rgba(30,87,153,1) 0%,rgba(244,246,247,1) 0%,rgba(225,231,234,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#e1e7ea',GradientType=1 );
  padding-bottom: 35px;
  @include media(xs) {
    background: transparent;
    padding-bottom: 0;
    display: block;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1.ui.header .content {
  padding-top: 20px;
}

.loading-blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.loading-blur-transition {
  -webkit-transition: 0.3s -webkit-filter linear;
  -moz-transition: 0.3s -moz-filter linear;
  -moz-transition: 0.3s filter linear;
  -ms-transition: 0.3s -ms-filter linear;
  -o-transition: 0.3s -o-filter linear;
  transition: 0.3s filter linear, 0.3s -webkit-filter linear;
}

.ui.red {
  color: #d9534f;
}

.ui.green {
  color: #00aa00;
}

.sylius-flash-message {
  text-align: center;

  .header {
    color: #ff6501;
    font-weight: bold;
  }
}

.checkbox-container {
  display: flex;
  input {
    width: 20px;
    height: 20px;
    border-color: #fff;
  }

  label {
    display: flex;
    background-color: transparent;
  }
}

.address-navigation {
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
  }

  .column-left {
    line-height: 48px;
  }

  .column-right {
    text-align: right;
    margin-bottom: 20px;
  }
}

.order-summary-address {
  display: flex;
  justify-content: space-around;
}

.centered-container {
  text-align: center;
  margin-bottom: 20px;
}

.sylius-order {
  img {
    max-width: 90px;
  }

  @include item-info();
}

.flex-container {
  display: flex;

  &.vertical-center {
    align-items: center;
  }
}

.sylius-cart-remove-button {
  min-width: auto;
  height: auto;
  margin-left: 10px;

  @include media(sm) {
    width: 30px;
    height: 30px;
  }

  @include media(xxs) {
    width: auto;
    height: 40px;
  }
}

.product-wrapper-list {
  margin-top: 15px;
}

.error-shake {
  -webkit-animation: error_shake 0.4s 1 linear;
  -moz-animation: error_shake 0.4s 1 linear;
  -o-animation: error_shake 0.4s 1 linear;
  animation: error_shake 0.4s 1 linear;
}

.error-shake-vertical {
  -webkit-animation: error_shake_vertical 0.4s 1 linear;
  -moz-animation: error_shake_vertical 0.4s 1 linear;
  -o-animation: error_shake_vertical 0.4s 1 linear;
  animation: error_shake_vertical 0.4s 1 linear;
}

@-webkit-keyframes error_shake {
  0% { -webkit-transform: translate(30px); }
  20% { -webkit-transform: translate(-30px); }
  40% { -webkit-transform: translate(15px); }
  60% { -webkit-transform: translate(-15px); }
  80% { -webkit-transform: translate(8px); }
  100% { -webkit-transform: translate(0px); }
}
@-moz-keyframes error_shake {
  0% { -moz-transform: translate(30px); }
  20% { -moz-transform: translate(-30px); }
  40% { -moz-transform: translate(15px); }
  60% { -moz-transform: translate(-15px); }
  80% { -moz-transform: translate(8px); }
  100% { -moz-transform: translate(0px); }
}
@-o-keyframes error_shake {
  0% { -o-transform: translate(30px); }
  20% { -o-transform: translate(-30px); }
  40% { -o-transform: translate(15px); }
  60% { -o-transform: translate(-15px); }
  80% { -o-transform: translate(8px); }
  100% { -o-transform: translate(0px); }
}

@-webkit-keyframes error_shake_vertical {
  0% { -webkit-transform: translateY(30px); }
  20% { -webkit-transform: translateY(-30px); }
  40% { -webkit-transform: translateY(15px); }
  60% { -webkit-transform: translateY(-15px); }
  80% { -webkit-transform: translateY(8px); }
  100% { -webkit-transform: translateY(0px); }
}
@-moz-keyframes error_shake_vertical {
  0% { -moz-transform: translateY(30px); }
  20% { -moz-transform: translateY(-30px); }
  40% { -moz-transform: translateY(15px); }
  60% { -moz-transform: translateY(-15px); }
  80% { -moz-transform: translateY(8px); }
  100% { -moz-transform: translateY(0px); }
}
@-o-keyframes error_shake_vertical {
  0% { -o-transform: translateY(30px); }
  20% { -o-transform: translateY(-30px); }
  40% { -o-transform: translateY(15px); }
  60% { -o-transform: translateY(-15px); }
  80% { -o-transform: translateY(8px); }
  100% { -o-transform: translateY(0px); }
}

.cart-container {
  margin-bottom: 20px;
}

.btn-gray {
  background-color: #D8D8D8;
}

.address-book-dropdown {
  .btn {
    border-radius: 0;
  }

  .dropdown-item {
    cursor: pointer;

    &:active {
      background-color: #ff6501;
    }
  }

  margin-bottom: 15px;
}

.acc-menu {
  padding-right: 0;
  margin: 0;
  justify-content: flex-end;
  display: flex;
  padding: 10px 0;
  @include media(xs) {
    display: none;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #7B8D8D;
    padding: 0 15px;
    font-size: 12px;

    @include media(sm) {
      padding: 0 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: #FF6501;
    }

    &:not(:last-child) {
      border-right: 1px solid #7B8D8D;
    }
  }
}

.account-header {
  font-size: 36px;
  padding-top: 20px;

  .sub.header {
    font-size: 20px;
    @include media(xs) {
      font-size: 16px;
    }
  }
}
.order-history-table {
  td {
    .ui.buttons {
      display: flex;
      align-items: center;

      a {
        margin: 0 10px 0 0;
        color: #FF6501;

        .card {
          display: none;
        }
      }
    }
  }
  tr {
    th {
      a {
        color: #FF6501;
        position: relative;
      }

      &.sortable {
        &.descending {
          a:after {
            content: none;
          }
        }

        &.ascending {
          a:before {
            content: none;
          }
        }

        a {
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 5px 4px;
            border-color: transparent transparent #FF6501 transparent;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: -20px;
          }

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 4px 0 4px;
            border-color: #FF6501 transparent transparent transparent;
            position: absolute;
            top: 50%;
            margin-top: 0px;
            right: -20px;
          }
        }
      }
    }
  }
}

.responsive-grid {
  td:before {
    display: none;
  }

  @include media(xs) {
    table, thead, tbody, th, td, tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      margin-left: 100px;
      font-size: 14px;
      padding: 5px;
    }
    td:before {
      display: block;
      position: absolute;
      top: 5px;
      left: 6px;
      padding-right: 40px;
      white-space: nowrap;
      margin-left: -100px;
      font-size: 14px;
    }
  }
}

.acc-info-nav-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @include media(xs) {
    display: block;

    form {
      display: block !important;
      button {
        display: block;
        width: 100%;
      }
    }
  }

  a, button {
    margin: 0 5px;
    @include media(xs) {
      margin: 0 0 10px;
      width: 100%;
    }
  }
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}
button.close-button {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.close-button {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.alert-dismissible {
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
  }
}

.address-table tr th {
  width: 200px;

  @include media(xs) {
    width: 120px;
  }
}

.alert,
.badge {
  border-radius: 0;
}

.badge {
  &.badge-orange {
    background-color: #ff6501;
    color: #fff;
    font-weight: normal;
  }
  &.badge-gray {
    background-color: #4d4d4d;
    color: #fff;
    font-weight: normal;
  }
}

.radio-button-custom {
  position: relative;
  margin: 10px;

  input {
    margin-bottom: 0;
    width: 20px;
  }

  .content {
    label {
      display: inline;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      line-height: 45px;
      background-color: transparent;
      font-size: 1rem;
      width: auto;
    }
  }
}

.paginate-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @include media(xs) {
    display: block;
  }

  .pagination {
    margin: 0;
    @include media(xs) {
      justify-content: center;
    }

    li {
      a {
        color: #ff6501;
        &:hover {
          background-color: transparent;
        }
      }
      &.active {
        .page-link {
          background-color: #ff6501;
          color: #fff;
          border-color: #ff6501;
        }
      }
    }
  }

  .paginate-display-in {
    display: flex;
    align-items: center;
    @include media(xs) {
      margin-bottom: 10px;
      justify-content: center;
    }

    .menu {
      margin-left: 4px;

      a {
        color: #ff6501;
      }
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ff6501;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff6501;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sylius-shop-account-order-grid .btn {
    padding-left: 0;

    @include media(xs) {
      font-size: 14px;
  }
}

.order-table-grid {
  tbody td:nth-of-type(1):before {
    top: initial;
    bottom: 35px;
  }

  tfoot td {
    margin-left: 0;
  }
}

.ui.content.container {
  min-height: 200px;
}

.images {
  display: inline-flex;
  justify-content: space-between;
  width: 87%;
  flex-wrap: wrap;

  .image {
    min-width: 150px;
    margin: 10px 0;
  }
}

.modal {
  top: auto;
  left: auto;
  height: auto;
}

.g-recaptcha {
  margin: 0 0 15px;
}

.register-after-checkout {
  text-align: center;
  margin-bottom: 20px;
}

.delivery-included {
  img {
    margin-left: -20px;
  }
  p {
    margin-top: -20px;
    margin-bottom: 0;
    color: #85a562;
    font-size: 16px;
    font-weight: bold;
  }
}

.price-notification {
  border: 1px solid #888d92;
  font-size: 18px;
  text-align: center;
  margin: 20px auto 0;
  padding: 18px;
  max-width: 820px;

  h4 {
    font-weight: 600;
  }

  a {
    color: black;
  }
}

.qr-scanner-container {
  text-align: center;

  .hidden {
    display: none;
  }
}
.qr-error-container {
  min-height: 1.5em;
}
