@mixin media($class) {
  @if $class == xxxs {
    @media (max-width: 320px) { @content; }
  }

  @else if $class == xxs {
    @media (max-width: 576px) { @content; }
  }

  @else if $class == xs {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == smUp {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == sm {
    @media (max-width: 991px) { @content; }
  }

  @else if $class == md {
    @media (max-width: 1199px) { @content; }
  }

  @else {
    @warn "Media mixin supports: xs, sm, md, smUp";
  }
}
